import React from "react";
import styles from "./AgreementsAndRules.module.css"
import SText from "./SText";
import { Helmet } from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import { useWindowDimensions } from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";

export default () => {

    const { windowDimensions: { width } } = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Соглашения и правила',
            to: '/the-agreements-and-rules'
        }
    ]

    return <div>
        <Helmet>
            <title>Соглашения и правила</title>
            <meta name="description" content='Соглашения и правила' />
            <meta name="keywords" content='Соглашения и правила' />
        </Helmet>
        <Header />
        { width < 992 && <MobileExtraInfo /> }
        <div className={ styles.wrapper }>
            <div className={ styles.container }>
                <BreadCrumbs _items={ breadCrumbs } />
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 24 } fontWeight={ 900 } lineHeight={ 23 }>Политика конфиденциальности</SText></div>
                <div style={ { marginBottom: width > 762 ? 28 : 18 } }><SText fontSize={ 16 } fontWeight={ 900 } lineHeight={ 15 }>Политика конфиденциальности разработана в
                    соответствии с законодательством Российской
                    Федерации.</SText></div>
                <div style={ { marginBottom: width > 762 ? 28 : 18 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Все лица, заполнившие сведения, составляющие персональные данные на данном сайте
                    seasonmarket.ru,
                    а
                    также
                    разместившие иную информацию, обозначенными действиями подтверждают свое согласие на обработку
                    персональных
                    данных и их передачу оператору обработки персональных данных.
                </SText></div>
                <div style={ { marginBottom: width > 762 ? 22 : 42 } }><SText fontSize={ 16 } fontWeight={ 900 } lineHeight={ 15 }>Под персональными данными Гражданина
                    понимается подписные формы и любая биографическая
                    информация:</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>общая информация (Ф. И. О. посетителя);</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>год, число и месяц рождения;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>номер телефона;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>почтовый адрес;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>email;</SText></div>
                <div className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>любая информация из социальных сетей;</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } } className={ styles.dot }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>серия, номер любого индивидуального документа.</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Посетители сайта направляют свои персональные данные оператору в целях получения какой-либо
                    необходимой посетителю информации, принадлежащей сайту seasonmarket.ru.</SText></div>
                <div style={ { marginBottom: width > 762 ? 30 : 30 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Гражданин, принимая настоящее соглашение, выражает свою заинтересованность и полное
                    согласие,
                    что
                    обработка их персональных данных может включать в себя следующие действия: сбор, систематизацию,
                    накопление,
                    хранение, уточнение (обновление, изменение), использование, уничтожение.</SText></div>
                <div style={ { marginBottom: 20 } }><SText fontSize={ 16 } fontWeight={ 550 } lineHeight={ 18 }>Гражданин гарантирует: информация, им предоставленная, является полной, точной и
                    достоверной;
                    при
                    предоставлении информации не нарушается действующее законодательство Российской Федерации, законные
                    права и
                    интересы третьих лиц; вся предоставленная информация заполнена Гражданином в отношении себя
                    лично.</SText>
                </div>

                <div>
                    <table className={ styles.table }>
                        <tr><td>Полное наименование</td> <td>Общество с ограниченной ответственностью «Умный сервис»</td></tr>
                        <tr><td>Сокращенное наименование</td> <td>ООО «Умный сервис»</td></tr>
                        <tr><td>Юридический адрес</td> <td>127006, г. Москва, ул. Краснопролетарская, д. 7, подвал/офис 1/105</td></tr>
                        <tr><td>Фактический (почтовый) адрес</td> <td>127006, г. Москва, ул. Краснопролетарская, д. 7, подвал/офис 1/105</td></tr>
                        <tr><td>Лист записи Единого государственного реестра юридических лиц</td> <td>выдан Межрайонной инспекция Федеральной налоговой службы № 46 по г. Москве 03.02.2022</td></tr>
                        <tr><td>Место государственной регистрации</td> <td>г. Москва</td></tr>
                        <tr><td>ОГРН</td> <td>1227700042646</td></tr>
                        <tr><td>ИНН</td> <td>7707461633</td></tr>
                        <tr><td>КПП</td> <td>770701001</td></tr>
                        <tr><td>ОКВЭД</td> <td>47.91, 10.71, 47.99, 56.10, 73.11, 73.12. 73.20.1</td></tr>
                        <tr><td>Руководитель</td> <td>Генеральный директор Басханова Берлант Умаровна</td></tr>
                        <tr><td>Банковские реквизиты:</td> <td></td></tr>
                        <tr><td>Расчетный счет</td> <td>40702810202370009754</td></tr>
                        <tr><td>Корреспондентский счет</td> <td>30101810200000000593</td></tr>
                        <tr><td>Полное наименование банка</td> <td>АО «АЛЬФА-БАНК»</td></tr>
                        <tr><td>БИК</td> <td>044525593</td></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

}