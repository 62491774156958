import DataContext from "../data/DataContext";
import { useHistory } from "react-router-dom";
import { useCoolDown, useWindowDimensions } from "../utils/hooks";
import { between } from "../utils/other";
import styles from "./Search.module.css";
import cn from "classnames";
import placeholder from "../assets/images/productImagePlaceholder.png";
import SText from "./SText";
import TouchableOpacity from "./TouchableOpacity";
import Colors from "../utils/Colors";
import envelope from "../assets/images/envelope.svg";
import plusWhite from "../assets/images/plusWhite.svg";
import shoppingCart from "../assets/images/cartPlus.svg";
import NewModal from "./NewModal";
import InformAboutAdmission from "./InformAboutAdmission";
import garbage from "../assets/images/garbage.svg";
import minusDarkBlue from "../assets/images/minusDarkBlue.svg";
import plusDarkBlue from "../assets/images/plusDarkBlue.svg";
import { useContext, useEffect, useRef, useState } from "react";
import freeze from "../assets/images/badges/freeze.svg"
import newProduct from "../assets/images/badges/new.svg"
import weekly from "../assets/images/badges/weekly.svg"
import preparation from "../assets/images/badges/preparation.svg";
import grain from "../assets/images/badges/grain.svg"
import seafood from '../assets/images/badges/seafood.png'

function getContentDimensions(width) {
    const breakPoints = [120, 200, 240]
    if (Math.floor(width) >= breakPoints[2])
        return {
            fontWeight: 700,
            fontSize: 18,
            padding: '16px 24px 0',
            bottomLinePadding: '0px 16px 16px 24px',
            priceFW: 700,
            priceFS: 22,
            borderRadius: '0px',
            ratio: .6,
            buttonSize: 53
        }

    return {
        fontWeight: 550,
        fontSize: 16,
        padding: '8px 0 0',
        bottomLinePadding: '0 0 2px 0',
        priceFW: 700,
        priceFS: 18,
        borderRadius: '6px',
        ratio: .7,
        buttonSize: 44,
    }
}

const AmountSelector = ({ amount, handleChange }) => {

    if (Number(amount) == 0)
        return null

    function onClick(delta) {
        return e => {
            handleChange(delta)
            e.stopPropagation()
        }
    }

    return <div className={ styles.amountSelectorWrapper }>
        <div className={ styles.amountSelector }>
            <TouchableOpacity onClick={ onClick(-1) } className={ cn(styles.selectorButton, styles.left) }>
                <img draggable={ false } src={ Number(amount) === 1 ? garbage : minusDarkBlue } />
            </TouchableOpacity>
            <SText fontSize={ 20 } fontWeight={ 700 } color={ '#213140' } style={ { pointerEvents: 'none' } }>
                { amount }
            </SText>
            <TouchableOpacity onClick={ onClick(1) } className={ cn(styles.selectorButton, styles.right) }>
                <img draggable={ false } src={ plusDarkBlue } />
            </TouchableOpacity>
        </div>
    </div>
}

const Badge = ({ img }) => {
    return <div className={ styles.badge }><img src={ img } /></div>
}

export const ProductCard = ({ id, out = false }) => {

    const { cart, auth } = useContext(DataContext)
    const [product, setProduct] = useState(null)
    const [amount, setAmount] = useState(0)
    const { push } = useHistory()
    const { windowDimensions: { width } } = useWindowDimensions()


    const nav = () => {
        if (!auth.data?.auth) {
            window.mindbox("async", {
                operation: "OnlineProductView",
                data: {
                    viewProduct: {
                        product: {
                            ids: {
                                sMProductID: product?.id
                            }
                        }
                    }
                }
            });

            window.mindbox("async", {
                operation: "SetViewedItemList",
                data: {
                    addProductToList: {
                        product: {
                            ids: {
                                sMProductID: product?.id,
                            }
                        },
                        pricePerItem: `${product?.price}`
                    }
                },
                onSuccess: function () {
                },
                onError: function (error) {
                }
            });


        }
        push('/product/' + product?.url)
    }

    const [informModalVisible, setInformModalVisible] = useState(false)

    const { getProductById } = useContext(DataContext)
    const cardRef = useRef(null)
    const isOut = out || product?.availableones < 1

    const recordValue = useCoolDown(cart.update, 600)

    async function loadData() {
        const product = await getProductById(id)
        setProduct(product)
    }

    function handleAmountChange(delta = 1) {
        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({ "type": "reachGoal", "id": 3220587, "goal": "addtocart" });

            window.fbq('track', 'AddToCart');


            window.gtag('event', 'add2cart', { 'event_category': 'ecommerce' });

            //window.yaCounter100345696.reachGoal('add2basket')

        } catch {
        }


        let newValue = between(0, amount + delta, product?.availableones || 0)
        setAmount(newValue)
        recordValue(id, newValue)
    }

    const getBadges = () => {
        let badges = []
        if (product.week == 1) badges.push(weekly)
        if (product.freeze == 1) badges.push(freeze)
        if (product.new == 1) badges.push(newProduct)
        if (product.preparation == 1) badges.push(preparation)
        if (product.grain == 1) badges.push(grain)
        if (product.seafood == 1) badges.push(seafood)
        return badges.map(badge => <Badge img={ badge } />)
    }

    useEffect(() => {
        const amount = cart.data?.productList?.find(item => item.product_id == id)?.quantity || 0
        setAmount(amount)
    }, [id, cart.data?.productList])


    const dimensions = getContentDimensions(cardRef.current?.offsetWidth || 280)


    const displayInfo = {
        actualPrice: product?.price * Math.max(amount, 1),
        actualOldPrice: product?.old_price * Math.max(amount, 1),
        weight: product?.weight * Math.max(amount, 1),
        discountPercents: product?.old_price ?
            Math.floor(100 - (product.price / product.old_price) * 100) :
            false
    }

    useEffect(() => {
        loadData()
    }, [id])

    if (!product) return <div className={ styles.productCard } ref={ cardRef } />

    return <div className={ styles.productCard } ref={ cardRef }>
        <div className={ styles.badgesWrapper }>
            { getBadges() }
        </div>
        <div className={ styles.productImage } onClick={ nav }>
            <img src={ product?.medium_image || '' }
                className={ cn(isOut && styles.imgOut) }
                style={ { borderRadius: 6 } }
                onError={ event => {
                    event.target.onerror = null;
                    event.target.src = placeholder
                } }
                draggable={ false } />
            <AmountSelector amount={ amount } handleChange={ handleAmountChange } />
            { product?.availableones == amount && product?.availableones != 0 ?
                <div className={ styles.quantityIsLimited }>
                    Количество данного товара ограничено
                </div> : null }
            { isOut && <div className={ styles.notifyMe } onClick={ () => setInformModalVisible(true) }>
                <SText fontSize={ 16 } fontWeight={ 700 } color={ '#fff' }>{ 'Сообщить о поступлении' }</SText>
            </div> }
        </div>
        <div className={ styles.productControllers }
            style={ { minHeight: (cardRef.current?.offsetWidth || 240) * dimensions.ratio } }>
            <div onClick={ nav }>
                <div className={ styles.productName } style={ { padding: dimensions.padding } }>
                    <SText div fontWeight={ dimensions.fontWeight } fontSize={ dimensions.fontSize }>
                        { product?.title || '' }
                    </SText>
                </div>
                <div className={ styles.weightBlock } style={ { padding: dimensions.padding } }>
                    <SText div fontWeight={ 700 } fontSize={ 14 } color={ "#909090" }>
                        { product?.weight + ' ' + (product.measure_unit === 'шт' ? 'кг' : product.measure_unit) }
                    </SText>
                </div>
            </div>
            <div className={ styles.bottomBlock } style={ { padding: dimensions.bottomLinePadding } }>
                <div className={ styles.priceBlock } onClick={ nav }>
                    { displayInfo.actualOldPrice ?
                        <div style={ { display: 'flex', alignItems: 'center' } }>
                            <div className={ styles.discount }>
                                <SText fontSize={ 16 } fontWeight={ 700 } color={ '#909090' }>
                                    { Math.ceil(displayInfo.actualOldPrice) }
                                </SText>
                                <SText fontSize={ 12 } fontWeight={ 700 } color={ '#909090' }
                                    style={ { fontWeight: 900 } }>{ '\u00A0₽' }</SText>
                            </div>
                            <div className={ styles.percents }>
                                <SText fontSize={ 12 } fontWeight={ 700 }
                                    color={ '#fff' }>{ `-${displayInfo.discountPercents}%` }</SText>
                            </div>
                        </div> :
                        null }
                    <div style={ { marginTop: 4 } }>
                        <SText fontSize={ 24 } fontWeight={ 700 }
                            color={ '#213140' }>{ Math.ceil(displayInfo.actualPrice) }</SText>
                        <SText fontSize={ 16 } fontWeight={ 700 } style={ { fontWeight: 900 } }>{ '\u00A0₽' }</SText>
                    </div>
                </div>
                <div className={ styles.buttonBlock }
                    onMouseDown={ isOut ? () => setInformModalVisible(true) : () => handleAmountChange(1) }>
                    <TouchableOpacity className={ cn(styles.button, isOut && styles.mailMe) }
                        style={ {
                            backgroundColor: (amount > 0 && !isOut) ? Colors.mainGreen : Colors.darkBlue,
                            width: dimensions.buttonSize,
                            height: dimensions.buttonSize,
                        } }>
                        <img draggable={ false } src={ isOut ? envelope : (amount > 0 ? plusWhite : shoppingCart) } />
                    </TouchableOpacity>
                </div>
            </div>
        </div>


        <NewModal isVisible={ informModalVisible } onClose={ () => setInformModalVisible(false) } fullScreen={ false }
            margin={ '0 36px' } minWidth={ width > 790 ? 781 : 303 }>
            <InformAboutAdmission onClose={ () => setInformModalVisible(false) } productId={ id } />
        </NewModal>
    </div>
}